<template>
  <div class="Details">
    <div class="bigImg"></div>
    <div class="detailTable">
      <div :class="control == 1 ? 'selectedBox' : 'otherBox'">
        <p @click="selectedCenter(1)"></p>
        <p @click="selectedCenter(2)"></p>
      </div>
      <ul>
        <li v-for="(item,i) in details" :key="i">
          <span>{{item.first}}</span>
          <span>{{item.second}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Details',
    data () {
      return {
        control: 1,
        details:[],
        detailsOne: [
          {
            first: '合约单位',
            second: '100',
          },
          {
            first: '货币单位',
            second: 'USD',
          },
          {
            first: '报价小数点',
            second: '2',
          },
          {
            first: '点差',
            second: '0.5美元',
          },
          {
            first: '单笔交易手数',
            second: '0.01手～30.00手',
          },
          {
            first: '手数差值',
            second: '0.01手',
          },
          {
            first: '最小挂单距离',
            second: '50',
          },
          {
            first: '初始保证金',
            second: '1000.00美元/手',
          },
          {
            first: '假期保证金',
            second: '双倍',
          },
          {
            first: '锁仓保证金',
            second: '500.00美元/手',
          },
          {
            first: '库存费',
            second: '-1.5%/-0.75%',
          },
          {
            first: '三天利息日',
            second: '周一',
          },
          {
            first: '交易时间GMT+8',
            second: '周一：06:00-24:00 \n 周二：00:00-04:55 06:00-24:00 \n 周三：00:00-04:55 06:00-24:00 \n 周四：00:00-04:55 06:00-24:00 \n 周五：00:00-04:55 06:00-24:00 \n 周六：00:00-04:45',
          },
          {
            first: '结算时间',
            second: '夏今：5点为结筤时间 \n 冬令：6点为结算时间',
          },
        ],
        detailsTwo: [
          {
            first: '合约单位',
            second: '5000',
          },
          {
            first: '货币单位',
            second: 'USD',
          },
          {
            first: '报价小数点',
            second: '3',
          },
          {
            first: '点差',
            second: '0.04美元',
          },
          {
            first: '单笔交易手数',
            second: '0.01手～30.00手',
          },
          {
            first: '手数差值',
            second: '0.01手',
          },
          {
            first: '最小挂单距离',
            second: '50',
          },
          {
            first: '初始保证金',
            second: '1000.00美元/手',
          },
          {
            first: '假期保证金',
            second: '双倍',
          },
          {
            first: '锁仓保证金',
            second: '500.00美元/手',
          },
          {
            first: '库存费',
            second: '-1.5%/-0.75%',
          },
          {
            first: '三天利息日',
            second: '周一',
          },
          {
            first: '交易时间GMT+8',
            second: '周一：06:00-24:00 \n 周二：00:00-04:55 06:00-24:00 \n 周三：00:00-04:55 06:00-24:00 \n 周四：00:00-04:55 06:00-24:00 \n 周五：00:00-04:55 06:00-24:00 \n 周六：00:00-04:45',
          },
          {
            first: '结算时间',
            second: '夏今：5点为结筤时间 \n 冬令：6点为结算时间',
          },
        ]
      }
    },
    created(){
      this.details = this.detailsOne;
      setTimeout(()=>{
        document.getElementsByClassName("navBarWrap")[0].style.height = '';
      },50)
    },
    mounted(){
      
    },
    methods: {
      selectedCenter(attr){
        if(attr == 1){
          this.control = attr;
          this.details = this.detailsOne;
        }else{
          this.control = attr;
          this.details = this.detailsTwo;
        }
      }
    }
  }
</script>

<style lang="less">
  .Details{
    width: 7.50rem;
    .bigImg{
      width: 100%;
      height: 9.2rem;
      background: url('../../static/details/wap/banner.jpg') no-repeat;
      background-size: cover;
    }
    .detailTable{
      width: 100%;
      box-sizing: border-box;
      padding: 0.85rem 0.4rem;
      margin: 0 auto;
      background: #f2f2f2;
      .selectedBox{
        width: 100%;
        height: 0.97rem;
        background: url('../../static/details/wap/change1.png') no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        p{
          width: 50%;
          height: 0.9rem;
          margin: 0;
        }
      }
      .otherBox{
        width: 100%;
        height: 0.97rem;
        background: url('../../static/details/wap/change2.png') no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        p{
          width: 50%;
          height: 0.9rem;
          margin: 0;
        }
      }
      ul{
        width: 100%;
        box-shadow:0px 0px 95px 0px rgba(43, 53, 105, 0.1);
        li:nth-child(even){
          background-color: #f4f4f9;
          box-sizing: border-box;
          padding: 0.3rem 0;
        }
        li:nth-child(odd){
          background-color: #ffffff;
          box-sizing: border-box;
          padding: 0.35rem 0;
        }
        li:last-child{
          border-bottom-left-radius: 0.16rem;
          border-bottom-right-radius: 0.16rem;
        }
        li{
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          // li里面的内容根据 \n 进行换行
          white-space: pre-line;
          span{
            display: block;
          }
          span:nth-child(1){
            width: 35%;
            font-size: 0.26rem;
            color: #121212;
            font-weight: 600;
            text-align: center;
          }
          span:nth-child(2){
            width: 65%;
            font-size: 0.24rem;
            color: #6d6d6d;
            text-align: center;
          }
        }
      }
    }
  }
</style>